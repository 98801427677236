<template>
  <header>
    <nav>
      <h1><router-link to='/'>Find the Coach</router-link></h1>
      <ul>
        <li><router-link to='/coaches'>Coaches</router-link></li>
        <li v-if='isLoggedIn'>
          <router-link to='/requests'>Requests</router-link>
          <span v-if='hasRequests'>{{ requestQuantity }}</span>
        </li>
        <li v-else><router-link to='/auth'>Login</router-link></li>
        <li v-if='isLoggedIn'><router-link to='/auth' @click='logout'>Logout</router-link></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    hasRequests() {
      return this.$store.getters['requests/hasRequest'];
    },
    requestQuantity() {
      return this.$store.getters['requests/requestQuantity'];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('coaches');
    }
  }
};
</script>

<style scoped>
header {
  width: 100%;
  height: 5rem;
  background-color: #3d008d;
  display: flex;
  justify-content: center;
  align-items: center;
}

header a {
  text-decoration: none;
  color: #f391e3;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid rgba(255,255,255,.2);
  transition: .3s;
}

a:active,
a:hover,
a.router-link-active {
  border: 1px solid #f391e3;
  color: white;
  background-color: rgba(255,255,255,.05);
}

h1 {
  margin: 0;
}

h1 a {
  color: white;
  margin: 0;
  border-color: transparent;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

header nav {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  position: relative;
  margin: 0 0.5rem;
}

li span {
  position: absolute;
  width: 16px;
  height: 16px;
  right: -6px;
  top: -6px;
  background-color: hotpink;
  color: white;
  font-size: 10px;
  justify-content: center;
  border-radius: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
</style>
