<template>
  <div>
    <base-dialog :show='!!error' title='An error ocurred!' @close='handError'>
      <p>{{ error }}</p>
    </base-dialog>
    <section>
      <coach-filter @change-filter='setFilters'></coach-filter>
    </section>
    <section>
      <base-card>
        <div class='controls'>
          <base-button mode='outline' @click='loadCoaches(true)'>Refresh</base-button>
          <base-button v-if='!isLoggedIn' to='/auth?redirect=register' link>Login to Register as Coach</base-button>
          <base-button v-if='isLoggedIn && !isCoach && !isLoading' to='/register' link>Register as coach</base-button>
        </div>
        <div v-if='isLoading'>
          <base-spinner></base-spinner>
        </div>
        <ul v-else-if='hasCoaches'>
          <coach-item v-for='coach in filteredCoaches'
                      :key='coach.id'
                      :id='coach.id'
                      :first-name='coach.firstName'
                      :last-name='coach.lastName'
                      :rate='coach.hourlyRate'
                      :areas='coach.areas'
          ></coach-item>
        </ul>
        <h3 v-else>
          Not coaches found
        </h3>
      </base-card>
    </section>
  </div>
</template>

<script>
import CoachFilter from '@/components/coaches/CoachFilter';
import CoachItem from '@/components/coaches/CoachItem';

export default {
  name: 'CoachesList',
  components: {
    CoachItem,
    CoachFilter
  },
  data() {
    return {
      isLoading: true,
      error: null,
      activeFilters: {
        frontend: true,
        backend: true,
        career: true,
      },
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    isCoach() {
      return this.$store.getters['coaches/isCoach'];
    },
    filteredCoaches() {
      const coaches = this.$store.getters['coaches/coaches'];
      return coaches.filter(coach => {
        if (this.activeFilters.frontend === true && coach.areas.includes('frontend')) {
          return true;
        }
        if (this.activeFilters.backend === true && coach.areas.includes('backend')) {
          return true;
        }
        if (this.activeFilters.career === true && coach.areas.includes('career')) {
          return true;
        }
        return false;
      })
    },
    hasCoaches() {
      return !this.isLoading && this.$store.getters['coaches/hasCoaches']
    }
  },
  created() {
    this.loadCoaches()
  },
  methods: {
    setFilters(updatedFilters) {
      this.activeFilters = updatedFilters;
    },
    async loadCoaches(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('coaches/loadCoaches', { forceRefresh: refresh });
      } catch (error) {
        this.error = error.message || "Something went wrong!"
      }
      this.isLoading = false;
    },
    handError() {
      this.error = null;
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
