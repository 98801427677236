<template>
<li>
  <h3>{{ fullName }}</h3>
  <h4>Rate: ${{ rate }} | ID: {{ id }}</h4>
  <div>
    <base-badge v-for='area in areas'
                :key='area'
                :title='area'
                :type='area'
    ></base-badge>
  </div>
  <div class='actions'>
    <base-button link mode='outline' :to='contactLink'>Contact</base-button>
    <base-button link :to='detailsLink'>View details</base-button>
  </div>
</li>
</template>

<script>
export default {
  name: 'CoachItem',
  props: [ 'id', 'firstName', 'lastName', 'rate', 'areas' ],
  computed: {
    fullName() {
      return this.firstName + ' ' + this.lastName
    },
    detailsLink() {
      return this.$route.path + '/' + this.id
    },
    contactLink() {
      return this.detailsLink + '/contact'
    },
  }
};
</script>

<style scoped>
li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0.5rem 0;
}

.actions {
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
}
</style>
