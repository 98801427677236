import { createRouter, createWebHistory } from 'vue-router';
import CoachesList from '@/views/coaches/CoachesList';
import NotFound from '@/views/NotFound';
import store from '@/store';

const CoachDetail = () => import('@/views/coaches/CoachDetail');
const CoachRegistration = () => import('@/views/coaches/CoachRegistration');
const ContactCoach = () => import('@/views/requests/ContactCoach');
const RequestsList = () => import('@/views/requests/RequestsReceived');
const UserAuth = () => import('@/views/auth/UserAuth');

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/coaches'
    },
    {
      path: '/coaches',
      component: CoachesList
    },
    {
      path: '/coaches/:id',
      component: CoachDetail,
      props: true,
      children: [
        {
          path: '/coaches/:id/contact',
          component: ContactCoach
        },
      ]
    },
    {
      path: '/register',
      component: CoachRegistration,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/requests',
      component: RequestsList,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/auth',
      component: UserAuth,
      meta: {
        requiresUnauth: true,
      }
    },
    {
      path: '/:notFound(.*)',
      component: NotFound
    }
  ]
})

router.beforeEach(function(to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/auth');
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/coaches');
  } else {
    next();
  }
});

export default router;
