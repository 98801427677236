export default {
  requests(state, _ , __ , rootGetters) {
    return state.requests.filter(req => req.coachId === rootGetters.userId);
  },
  hasRequest(state, getters) {
    return getters.requests && getters.requests.length > 0;
  },
  requestQuantity(state, getters) {
    return getters.length;
  }
};
