<template>
  <section>
    <base-card>
      <h2>Page Not Found</h2>
      <p>
        This page could not be found - maybe check out all our <router-link to='/coaches'>coaches</router-link>
      </p>
    </base-card>
  </section>
</template>

<script>
import BaseCard from '@/components/ui/BaseCard';
export default {
  name: 'NotFound',
  components: {BaseCard}

};
</script>

<style scoped>

</style>
